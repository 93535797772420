import React, { useState } from 'react'
import QuickMenu from './quickMenu';

function TopNavItem(props) {

    const { id, icon, activeTopNav, handleClick, func } = props

    const [notifications, setNotifications] = useState(0);

    return (
        <div className="quick-item">
            <div className='iconDiv'><i onClick={() => handleClick(id)} class={icon} /></div>
            <QuickMenu id={id} active={id === activeTopNav} setNotifications={setNotifications} func={func} />
            {notifications > 0 ? <span className="notification-count">{notifications > 99 ? "99+" : notifications}</span> : <></>}
        </div>
    )
}


export default TopNavItem;