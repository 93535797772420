import React, { useEffect } from 'react'
import { Frame, Dropdown } from 'jmap-common-components'
function Settings() {
    const themes = ['Default', 'Dark', 'Alternative', 'Batman', 'Woodlands', 'Sapphire', 'Tiger']
    const message = new CustomEvent('style-message', {
        detail: {
            trigger: "true"
        }
    });

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const theme = localStorage.getItem("theme") ?? "./themes/default.css"
        document.getElementById("themeSelection").setAttribute("href", theme)
        sleep(200).then(() => {
            window.dispatchEvent(message)
        })
    }, [])

    const handleSelectedTheme = (index) => {
        const theme = `./themes/${themes[index].toLowerCase()}.css`;
        document.getElementById("themeSelection").setAttribute("href", theme)
        localStorage.setItem("theme", theme)
        window.dispatchEvent(message)
    }

    return (
        <Frame title={'Settings'} className={'bb-settings settings-frame'} fixed={true} >
            <Dropdown data={themes} label={'Color Scheme'} sendSelection={handleSelectedTheme} />
        </Frame>
    )
}

export default Settings