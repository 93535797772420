import React from 'react'
import Draggable from 'react-draggable';

function QuickMenu(props) {

    const { active, id, func, setNotifications } = props;
    return (
        <Draggable>
            <div className={active ? 'quick-menu' : 'quick-menu hide'}>
                {func ? func(setNotifications) : "no content :("}
            </div>
        </Draggable>
    )
}

export default QuickMenu;