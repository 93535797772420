import React from 'react';
import TopNavItem from './topNavItem';
import { BootstrapIcon } from './sideNav';

function TopNav(props) {

    const { handleClick, items, activeTopNav } = props

    return (
        Object.keys(items).map((id) => {
            return (
                <TopNavItem key={id} icon={items[id].icon} id={items[id].title} activeTopNav={activeTopNav} func={items[id].function} handleClick={handleClick} />
            )
        })
    )
}

export default TopNav;

