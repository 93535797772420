import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

import UndefinedIcon from "bootstrap-icons/icons/emoji-frown-fill.svg";

import TopNav from "./dashboard/topNav";
import SideNav from "./dashboard/sideNav";

import { Frame, useRootRest, Notification } from "jmap-common-components";
import SettingsNFE from "./settings/settingsNFE";
import { useKeycloak } from "@react-keycloak/web";

/**
 * @author southb
 */
function Dashboard({ splashScreenOn, setSplashScreenOn }) {
    const title = "Dashboard";
    const { keycloak, initialized } = useKeycloak();

    const [sideNavItems, setSideNavItems] = useState([
        {
            title: "Home",
            icon: "bi bi-house-door-fill",
            route: "#/dashboard",
        },
    ]);

    const [topNavItems, setTopNavItems] = useState({});

    useEffect(() => {
        keycloak.loadUserProfile().then((profile) => {
            console.log(profile.username);
            setTopNavItems((prevItems) => ({
                ...prevItems,
                account: {
                    title: "Account",
                    icon: "bi bi-person-circle",
                    function: () => {
                        return (
                            <Frame
                                fixed={true}
                                id="account"
                                title="Account"
                                className="nfe-account-frame"
                            >
                                <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
                                    Logged in as {profile.username}
                                </div>
                                <button
                                    className="bb-button"
                                    onClick={() => {
                                        setActiveTopNav("none");
                                        keycloak.logout();
                                    }}
                                >
                                    Log out
                                </button>
                            </Frame>
                        );
                    },
                },
            }));
        });
    }, []);

    const [sideNavExpanded, setSideNavExpanded] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("none");
    const [notification, setNotification] = useState("Initializing");
    const [configurations] = useRootRest("/service-info/configurations");

    const currentNavItems = useRef([]);

    useEffect(() => {
        fetch("https://" + window.location.host + "/rest/service-info").then(
            (response) => {
                response.json().then((message) => {
                    updateServiceInfo(message);
                });
            }
        );

        window.addEventListener("service-info", (message) => {
            updateServiceInfo(JSON.parse(message.detail));
        });
    }, []);

    function updateServiceInfo(json) {
        sideNavItems.forEach((navItem) => {
            if (!currentNavItems.current.includes(navItem.route))
                currentNavItems.current.push(navItem.route);
        });
        $.each(json, async (key, value) => {
            const route = value.routeObj.route;
            const icon = value.routeObj.icon;
            //console.log(`${key}, ${route}, ${icon}`)
            if (route !== "#" && route) {
                if (!currentNavItems.current.includes(route)) {
                    currentNavItems.current.push(route);
                    const splitRoute = route.split("/")[1];
                    const title =
                        splitRoute.charAt(0).toUpperCase() + splitRoute.slice(1);
                    addSideNavItem(title, icon, route);
                }
            }
        });
    }

    useEffect(() => {
        window.addEventListener("dash-notification", (event) => {
            if (event.detail.notification) {
                setNotification(event.detail.notification);
            }
        });
    }, []);

    useEffect(() => {
        window.addEventListener("dash-message", handleIncomingMicroMfe);
        const splitLocation = window.location.href.split("/");
        if (splitLocation[splitLocation.length - 1] === "") {
            window.location.href = "#/dashboard";
        }
    }, []);

    const splash = () => {
        setSplashScreenOn(true);
    };

    const addSideNavItem = (t, i, r) => {
        if (i) {
            setSideNavItems((current) => [
                ...current,
                { title: t, icon: i, route: r },
            ]);
        } else {
            setSideNavItems((current) => [
                ...current,
                { title: t, icon: UndefinedIcon, route: r },
            ]);
        }
    };

    const handleTopNavItem = (id) => {
        if (id === activeTopNav) {
            setActiveTopNav("none");
        } else {
            setActiveTopNav(id);
        }
    };

    const handleIncomingMicroMfe = (event) => {
        window.dispatchEvent(
            new CustomEvent("nfe-received", {
                detail: {
                    id: event.detail.id,
                },
            })
        );
        const microMfeId = event.detail.id;
        const microMfeIcon = event.detail.icon;
        const microMfeFn = event.detail.function;

        setTopNavItems((prevTopNavItems) => {
            return {
                ...prevTopNavItems,
                [microMfeId]: {
                    title: microMfeId,
                    icon: microMfeIcon,
                    function: microMfeFn,
                },
            };
        });
    };

    return (
        <div>
            <>
                <SettingsNFE />
                <div className={"banner"}
                    style={{ backgroundColor: configurations.bannerColor !== "" ? configurations.bannerColor : "black", display: configurations.bannerColor === "" ? "none" : "inline"}}>
                    <span>{configurations.bannerTitle}</span>
                </div>
                <div className={configurations.bannerColor !== "" ? "top-nav" : "top-nav no-banner"}>
                    <div>
                        <div
                            className="side-nav-ctrl"
                            onClick={() => setSideNavExpanded(!sideNavExpanded)}
                        >
                            {sideNavExpanded ? (
                                <i class={"bi bi-arrow-left-short"} />
                            ) : (
                                <i class={"bi bi-arrow-right-short"} />
                            )}
                        </div>
                        <span className="title">{configurations.dashboardTitle}</span>
                        <span className={configurations.showClassification === "true" ? (configurations.domain === "NIPR" ? "nipr" : (configurations.domain === "TS" ? "ts" : "")) : "hide"}>
                            {configurations.platform}:{configurations.domain}
                        </span>
                    </div>
                    <div>
                        <TopNav
                            items={topNavItems}
                            activeTopNav={activeTopNav}
                            handleClick={handleTopNavItem}
                        />
                    </div>
                </div>
                <div
                    className={
                        sideNavExpanded ? "side-nav expanded" : "side-nav collapsed"
                    }
                >
                    <SideNav
                        expanded={sideNavExpanded}
                        items={sideNavItems}
                        splash={splash}
                    />
                </div>
                <div className={splashScreenOn ? "splash-screen" : "hide"}>
                    <div className="spinner">
                        <div className="mask">
                            <div className="maskedCircle"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="notification-container">
                        <Notification notification={notification} />
                    </div>
                </div>
            </>
        </div>
    );
}

export default Dashboard;
