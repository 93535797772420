import React from 'react';

function SideNav(props) {

    const { items, expanded, splash } = props

    const jump = (route) => {
        if (!window.location.href.endsWith(route)) {
            console.log(`${route} !== ${window.location.href}`)
            splash();
        }
    }

    return items.map((item, index) => {
        if (typeof item.icon === 'string' || item.icon instanceof String) {
            return (
                <div key={index} className='entry'>
                    <a title={item.title} href={item.route} style={{ fontSize: '1.5vmin', color: 'white', textDecoration: 'none' }} onClick={() => jump(item.route)}>
                        {expanded ? <span><i class={item.icon} /> &nbsp;&nbsp;&nbsp; {item.title}</span> : <span><i class={item.icon} /></span>}
                    </a>
                </div>
            )
        }
    })
}

export default SideNav;