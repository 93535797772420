import React from 'react'
import { NFE } from "jmap-common-components"
import Settings from './settings'

export default function SettingsNFE() {
    return NFE({
        id: "sr-settings",
        icon: "bi bi-gear",
        component: (setNotifications) => {
            return (
                <Settings setNotifications={setNotifications} />
            )
        }
    })
}

