import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useState, useEffect } from 'react'
import Dashboard from './dashboard';
import { getAuthProvider } from '@L3Harris/jmap-auth-utility';

export default function LoginScreen() {

  const [splashScreenOn, setSplashScreenOn] = useState(true)

  useEffect(() => {
    window.addEventListener('splash-message', (event) => {
      if (event.detail) {
        if (event.detail.apps === 'ready') {
          setSplashScreenOn(false)
        }
      }
    });
  }, [])



  return (
    <ReactKeycloakProvider authClient={getAuthProvider()} initOptions={{ onLoad: 'login-required' }}
      LoadingComponent={<div className={'splash-screen'}>
        <div className="spinner">
          <div className="mask">
            <div className="maskedCircle"></div>
          </div>
        </div>
      </div>} >
      <Dashboard splashScreenOn={splashScreenOn} setSplashScreenOn={setSplashScreenOn}/>
    </ReactKeycloakProvider>);

}
